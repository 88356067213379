// Custom Sliders Adjustments

// New color variations for the sliders
@each $color, $value in $new-colors {
  .slider-#{$color} .noUi-connect {
    background: $value;
  }
}

// Accent color slider
.slider-accent .noUi-connect {
  background: $slider-accent-background;
}

.noUi-pips {
  font-size: $slider-pips-font-size;
}

.noUi-tooltip {
  font-size: $slider-tooltip-font-size;
  padding: $slider-tooltip-padding;
}

.noUi-horizontal {
  .noUi-tooltip {
    bottom: $slider-horizontal-tooltip-bottom;
  }

  .noUi-handle {
    left: $slider-horizontal-handle-left;
    top: $slider-horizontal-handle-top;
  }
}

.noUi-handle {
  width: $slider-handle-width;
  height: $slider-handle-height;
}
