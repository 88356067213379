// Dropdown adjustments

// Adjust dropdown icons
.dropdown {
  .fa,
  .material-icons {
    color: lighten($blueish-grey, 40);
    margin-right: 0.25rem;
  }
  

  .dropdown-item {
    &:hover {
      background-color: lighten($blueish-grey, 60);
    }
  }
  
  .field.dropdown-toggle::after {
    display: none !important;
}
.field.fa
 {
  color: rgb(0, 113, 188) !important;
  
}
}
