.tableHeader{
    background-color: #fff;
    padding: 4px 12px;
    position: absolute;
    z-index: 9;
    border-radius: 40px;
    top: -13px;
    left: 51px;
    border: 1px solid #dcdcdc;
    color:#0071bc
}