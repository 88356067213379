// Shards Charts (sc) styles

// Legends
.sc-legend-container {
  padding: 0;
  margin: 0;
  padding: 10px 30px 7px 30px;

  li {
    display: inline-block;
    margin-right: .75rem;
    font-size: 12px;
    color: $reagent-gray;
  }
}

// Labels
.sc-legend {
  position: relative;
  padding-left: 25px;

  &__label {
    position: absolute;
    width: 20px;
    border-radius: 10px;
    height: 2px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Chart tooltips
div[class^="sc-tooltip-"] {
  background: $chart-tooltip-background-color;
  border-radius: $chart-tooltip-border-radius;
  font-size: 11px;
  opacity: 1;
  position: absolute;
  transition: all .1s ease;
  transform: translate(-50%, 0);
  padding: $chart-tooltip-spacer-y $chart-tooltip-spacer-x;
  box-shadow: $chart-tooltip-box-shadow;
  pointer-events: none;
  min-width: 120px;
  white-space: nowrap;

  thead {
    text-align: center;

    tr th {
      font-weight: 500;
    }
  }
}

.sc-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
