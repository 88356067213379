.personalParent{
    .list-group-flush .list-group-item{
        border-radius:0.625rem ;
    }
}
.headerStyle {
    position: absolute;
    z-index: 1;
    background: #fff;
    color: #0071bc !important;
    padding: 0 29px;
    border-radius: 20px;
    top: -15px;
    left: 30px;
    display: inline;
    font-weight: 700;
    border: 1px solid #dcdcdc;
    font-size: 0.938rem;

  @media (min-width: 1200px) {
    left:76px;
  }
}
.idStyle {
    position: absolute;
    z-index: 1;
    background: #fff;
    color: #0071bc !important;
    padding: 0 29px;
    border-radius: 20px;
    top: -15px;
    right: 10px;
    display: inline;
    font-weight: 700;
    border: 1px solid #dcdcdc;
    font-size: 0.938rem;

  @media (min-width: 1200px) {
    right:76px;
  }
}


.avatarImg {
    width: 50px;
    border-radius: 50px;
    position: absolute;
    z-index: 2;
    top: -24px;
    left: 10px;
  @media (min-width: 1200px) {
    left:47px;
  }
}

.iconBox {
    width: 50px;
    height: 50px;
    background: #fff;
    position: absolute;
    z-index: 2;
    top: -24px;
    left: 10px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0071bc;
    border-bottom: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;

  @media (min-width: 1200px) {
    left:47px;
  }
}
.inputBoxClr {
    background-color: #f3f4f6 !important;
    border: 1px solid #bcbcbc !important;
}
.inputBoxClr:hover{
    border: 1px solid #333 !important;
}
.iconStyle{
    font-size: 22px;
}
.leaveTable{
    .card{
        box-shadow:none !important;
    }
    .file-manager__filters__rows{
        align-items:initial !important;
    }
}
